import * as React from "react";
import { Link } from "gatsby";

import wochen from "../data/wochen.json";
import Seo from "../components/Seo";

import { Container, Button } from "../components/Design";

// markup
const IndexPage = () => {
  return (
    <Container style={{ marginTop: "60px" }}>
      <Seo />
      {wochen.map((woche, index) => (
        <Link to={`/${woche.slug}`} key={`${woche.slug}-${index}`}>
          <Button>Woche {woche.slug}</Button>
        </Link>
      ))}
    </Container>
  );
};

export default IndexPage;
